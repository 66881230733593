<template>
<v-col>
  <v-row>
    <v-col cols="12" lg="8" md="12">
        <v-row>
            <v-col cols="12" md="4" v-for="(list_serveur,
                                                index) in list_serveur" :key="index">
                <!-- test -->
                <base-card color="#34495E">
                    <v-card-text class="d-flex align-center h-full">
                        <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                            <div>
                                <p class="ma-0 white--text">

                                    Pays: {{ list_serveur.pays }}
                                </p>
                                <VueMomentsAgo prefix="posted" suffix="ago" date="2018-05-02T20:22:22.285Z" lang="fr"></VueMomentsAgo>
                                <v-row>
                                    <v-col md="4" cols="3">
                                        <p class="ma-0 white--text">Etat serveur:</p>
                                    </v-col>
                                    <v-col md="8" cols="6" >
                                        <v-chip
                                        class="ma-0"
                                        color="primary"
                                        >
                                        {{ Etat_moment(list_serveur.etat_time)  }}
                                        </v-chip>
                                    </v-col>


                                </v-row>
                                <div class="text-h5 white--text ma-0 ">
                                    Serveur: {{ list_serveur.code_server }}
                                </div>
                                <p class="ma-0 white--text">
                                    [ Dépôts ({{ list_serveur.D }}) / Paiements ({{ list_serveur.R }}) ]
                                </p>
                            </div>
                            <v-switch
                            class="white--text"
                            dark
                            :color="detStatus(list_serveur)==true?'green':'red'"
                            inset hide-details
                            @change="save_status(list_serveur)"
                            v-model="list_serveur.status"
                            :label="detStatus(list_serveur)==true?'ON':'OFF'"></v-switch>

                        </div>
                    </v-card-text>
                </base-card>
            </v-col>

        </v-row>
    </v-col>
    <v-col cols="12" lg="4" md="12">
        <base-card>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab v-for="item in items" :key="item.tab">
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item v-for="item in items" :key="item.tab">
                        <v-card flat>
                            <v-card-text>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Monnaie
                                            </th>
                                            <th class="text-left">
                                                Reserve
                                            </th>
                                            <th class="text-left">
                                                Statut
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(itemTwo,
                                                index) in item.content" :key="index">
                                            <td>{{ itemTwo.libelle_monnaie }}</td>
                                            <td>
                                                <p :class="
                                                            getColor(
                                                                itemTwo
                                                            )
                                                        ">
                                                    {{ itemTwo.reserve }}
                                                </p>
                                            </td>
                                            <td>

                                                <v-chip :class="
                                                            changeColor(
                                                                itemTwo.status
                                                            )
                                                        " small label class="ml-2">
                                                    {{ itemTwo.status==1?'Activer':'Desactiver' }}
                                                </v-chip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </base-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12" lg="12" md="12">

       <!--Titre statistque au centre-->
       <h2 class="text-center">Statistiques Globales</h2>

       <v-row>

        <v-col cols="5" lg="2">
          <v-menu v-model="menu" :close-on-content-click="false">
            <v-text-field
              :value="date_debut"
              slot="activator"
              v-model="date_debut"
              prepend-icon="mdi-calendar"
              clearable label="Date debut"
            ></v-text-field>
              <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="5" lg="2">
          <v-menu>
            <v-text-field
              :value="date_fin"
              slot="activator"
              v-model="date_fin"
              prepend-icon="mdi-calendar"
              label="Date fin"
            ></v-text-field>
              <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readStatistics">
                Recherche
            </v-btn>

        </v-col>

      </v-row>

      <v-row>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Total transactions du jour
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                   {{ statistique.total_transactions }}
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Total Entrée du jour
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.total_entree }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Total Sortie du jour
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.total_sortie }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Montant maximal entrée
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.max_entree }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Montant maximal sortie
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.max_sortie }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Montant minimal entrée
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.min_entree }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Montant minimal sortie
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.min_sortie }} F CFA
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                  <div>
                                <p class="ma-0 white--text text-center">
                                    Total utilisateurs inscrits
                                </p>

                                <div class="text-h5 white--text ma-0 text-center">
                                    {{ statistique.total_users }}
                                </div>

                  </div>
            </div>
          </v-card-text>
        </base-card>
        </v-col>

      </v-row>
    </v-col>

  </v-row>

  <v-row>
    <v-col cols="12" lg="12" md="12">

       <!--Titre statistque au centre-->
       <h2 class="text-center">Statistiques par pays</h2>

       <v-row>

        <v-col cols="5" lg="2">
          <v-menu v-model="menu" :close-on-content-click="false">
            <v-text-field
              :value="date_debut"
              slot="activator"
              v-model="date_debut"
              prepend-icon="mdi-calendar"
              clearable label="Date debut"
            ></v-text-field>
              <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="5" lg="2">
          <v-menu>
            <v-text-field
              :value="date_fin"
              slot="activator"
              v-model="date_fin"
              prepend-icon="mdi-calendar"
              label="Date fin"
            ></v-text-field>
              <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
          </v-menu>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readStatistics">
                Recherche
            </v-btn>

        </v-col>

      </v-row>

      <v-row>

        <v-col cols="12" md="4" v-for="(statistique_pays,
                                                index) in statistique_pays" :key="index">
          <base-card color="#34495E">
          <v-card-text class="d-flex align-center h-full">
            <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
              <v-col>
                <h4 class="ma-0 white--text text-center">{{ statistique_pays.pays }}</h4>
                <v-row>
                  <p class="ma-0 white--text text-center">Total transactions du jour : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.total_transactions }}</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Total entree : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.total_entree }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Total sortie : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.total_sortie }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Max entree : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.max_entree }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Max sortie : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.max_sortie }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Min entree : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.min_entree }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Min sortie : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.min_sortie }} FCFA</p>
                </v-row>

                <v-row>
                  <p class="ma-0 white--text text-center">Total inscrit : </p>
                  <p class="ma-0 white--text text-center">  {{ statistique_pays.total_user }}</p>
                </v-row>


              </v-col>


            </div>
          </v-card-text>
        </base-card>
        </v-col>



      </v-row>
    </v-col>

  </v-row>

  <v-row>

    <v-col cols="12" lg="12" md="12">
      <h2 class="text-center">Historique transactions par jour</h2>

      <v-row>

          <v-col cols="5" lg="2">
            <v-menu v-model="menu" :close-on-content-click="false">
              <v-text-field
                :value="date_debut"
                slot="activator"
                v-model="date_debut"
                prepend-icon="mdi-calendar"
                clearable label="Date debut"
              ></v-text-field>
                <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
            </v-menu>

          </v-col>
          <v-col cols="5" lg="2">
            <v-menu>
              <v-text-field
                :value="date_fin"
                slot="activator"
                v-model="date_fin"
                prepend-icon="mdi-calendar"
                label="Date fin"
              ></v-text-field>
                <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
            </v-menu>

          </v-col>
          <v-col>
              <v-btn color="primary" dark class="mb-2" @click="readStatistics">
                  Recherche
              </v-btn>

          </v-col>

      </v-row>

      <v-col cols="12" lg="12" md="12">
        <base-card>
          <v-card-text>
            <apexchart
              type="line"
              width="100%"
              height="365"
              :options="chartOptions"
              :series="chartSeries"
            />
          </v-card-text>
        </base-card>
      </v-col>

    </v-col>

  </v-row>

</v-col>

</template>

<script>
import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'
import ChartCard from '@/components/card/ChartCard'
import api from '@/serviceApi/apiService'
import moment from 'moment'
import VueMomentsAgo from 'vue-moments-ago'

export default {
    name: 'Dashbord',
    components: {
    VueMomentsAgo
  },
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashbord'
    },
    components: {
        CryptoCurrencyCard,
        ChartCard
    },
    data() {
        return {

            date_debut: null,
            date_fin: null,

            statistique:null,
            statistique_pays:[],

             charts:null,
            chartSeries:[],
            chartMarkers:[],
            chartStrokeWidth: [],
            chartCategories: [],
            chartOptions: {},
            chartOptions2: {

            },
            chartColors:[],

            tab: null,
            items: [],

            list_serveur: [],
            list_serveur2: [],
        }
    },
    created() {

          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth()+1; //January is 0!
          let yyyy = today.getFullYear();
          this.date_debut = yyyy+'-'+mm+'-'+dd;
          this.date_fin = yyyy+'-'+mm+'-'+dd;

        this.readAll_serveur();
        this.readStatistics();
        //this.readCharts();

    },
    methods: {
        readAll_serveur: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-dashbord', fd);
            this.list_serveur2 = data.servers;

            this.items = data.monaire;

            for(let i=0;i<this.list_serveur2.length;i++){
               // this.list_serveur2[i]['times']=Date.now()-parseInt(this.list_serveur2[i].last_update);
                this.list_serveur2[i]['etat_time']=parseInt(this.list_serveur2[i].last_update);
                if(this.list_serveur2[i].statut==1){
                this.list_serveur2[i]['status']=true;
               }else{
                this.list_serveur2[i]['status']=false;
               }

            }
            this.list_serveur = this.list_serveur2;
           // console.log(this.list_serveur);

        },

        readStatistics: async function () {
            let fd = new FormData();
            fd.append('date_debut', this.date_debut);
            fd.append('date_fin', this.date_fin);
            const data = await api.createUpdatedata('backoffice/statistiques', fd);
            this.statistique = data.statistique;
            this.statistique_pays = data.statistique_pays;
        },

        readCharts: async function () {
            let fd = new FormData();
            let oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
            let dd = oneMonthAgo.getDate();
            let mm = oneMonthAgo.getMonth()+1; //January is 0!
            let yyyy = oneMonthAgo.getFullYear();
            this.date_debut = yyyy+'-'+mm+'-'+dd;

            fd.append('date_debut', this.date_debut);
            fd.append('date_fin', this.date_fin);
            const data = await api.createUpdatedata('backoffice/chart_transactions', fd);
            this.charts = data;
            this.chartSeries = this.generateChartSeries(this.charts.chart_globale, this.charts.chart_pays);
            this.chartCategories = this.getChartCategories();
            this.chartColors = this.generateRandomColor(this.charts.chart_pays);
            this.chartMarkers = this.generateRandomMarker();
            this.chartStrokeWidth = this.generateRandomStrokeWidth(this.charts.chart_pays);
            this.chartOptions = {
                chart: {
                  height: 350,
                  type: 'line',
                  dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                colors: this.chartColors,
                stroke: {
                  width: this.chartStrokeWidth,
                  curve: 'straight',
                },
                markers: {
                  size: this.chartMarkers,
                },
                dataLabels: {
                  enabled: false,
                },
                grid: {
                  strokeDashArray: 3,
                },
                xaxis: {
                  categories: this.chartCategories,
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
            };


        },

        getChartCategories: function () {
          let categoriesArray = [];
          for (let i = 0; i < this.charts.chart_globale.length; i++) {
            categoriesArray.push(this.charts.chart_globale[i].date);
          }
          return categoriesArray;
        },

        //generate random marker size (integer) array based on number of items in chartCategories
        generateRandomMarker: function () {
            let markerArray = [];
            let random = Math.floor(Math.random() * 10) + 1;
            for (let i = 0; i < this.chartCategories.length; i++) {
                markerArray.push(random);
            }
            return markerArray;
        },

        //generate chartSeries based on two arrays
        generateChartSeries: function (chart_globale, chart_pays) {
            let seriesArray = [];


            for (let i = 0; i < chart_pays.length; i++) {
                let serieObject = {};
                serieObject.name = chart_pays[i].pays;
                serieObject.data = [];
                let chart = chart_pays[i].chart;
                for (let j = 0; j < chart.length; j++) {
                  // convert total to integer
                    serieObject.data.push(parseInt(chart[j].total));
                }
                seriesArray.push(serieObject);
            }

            // add chart_globale to seriesArray
            let serieObject2 = {};
            serieObject2.name = 'Globale';
            serieObject2.data = [];
            for (let i = 0; i < chart_globale.length; i++) {
                serieObject2.data.push(parseInt(chart_globale[i].total));
            }
            seriesArray.push(serieObject2);

            return seriesArray;
        },

        //generate random color array (hexa code) based on number of modalities
        generateRandomColor: function (chart_pays) {
            let colorArray = [];
            for (let i = 0; i < chart_pays.length; i++) {
                colorArray.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            }
            colorArray.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            return colorArray;
        },


        //generate random stroke width (integer) array based on number of modalities
        generateRandomStrokeWidth: function (chart_pays) {
            let strokeWidthArray = [];
            for (let i = 0; i < chart_pays.length; i++) {
                strokeWidthArray.push(Math.floor(Math.random() * 5) + 1);
            }
            strokeWidthArray.push(Math.floor(Math.random() * 5) + 1);
            return strokeWidthArray;
        },



        save_status: async function (item) {
            console.log(item.statut);
            let fd = new FormData();
            fd.append('id', item.id);
            fd.append('statut',item.statut==1?0:1 );
            const data = await api.createUpdatedata('backoffice/etat-server', fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');

            } else {
                this.showAlert(data.message, 'error');

            }
            this.readAll_serveur();
        },

        detStatus(item) {
            let time = Date.now() - parseInt(item.last_update);
            if ((item.status == 1) && (time < 90000)) {
                return true;
            } else {
                return false;
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },
         Etat_time(item){
            if(item.etat_time<90000){
                return 'red';
            }else{
                return 'green';
            }
         },
         Etat_moment(item){
            return moment(item).locale('fr').fromNow();
         },


        getColor(item) {
            if (item.reserve < 5000 && item.type_monnaie=='Local') return 'danger--text'
            else if (item.reserve > 5000 && item.type_monnaie=='Local') return 'success--text'

            else if (item.reserve < 500 && item.type_monnaie=='Dollars') return 'success--text'
            else if (item.reserve > 500 && item.type_monnaie=='Dollars') return 'success--text'

            else if (item.reserve < 0.5 && item.libelle_monnaie=='BNB') return 'success--text'
            else if (item.reserve > 0.5 && item.libelle_monnaie=='BNB') return 'success--text'

            else if (item.reserve < 100 && item.libelle_monnaie=='TRON') return 'success--text'
            else if (item.reserve > 100 && item.libelle_monnaie=='TRON') return 'success--text'

            else if (item.reserve < 500 && item.type_monnaie=='Crypto') return 'success--text'
            else if (item.reserve > 500 && item.type_monnaie=='Crypto') return 'success--text'
             return 'priamry--text'
        },
        changeColor(number) {
            if (number == 0) return 'danger white--text'
            else if (number == 1) return 'success white--text'
            else return 'primary white--text'
        }
    },
    mounted() {
      this.readCharts();
    }
}
</script>
